.account-container {
  width: 95%;
  margin: 50px auto 250px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.account-header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.account-header-container h6 {
  font-weight: 800;
}

.account-header-container span {
  padding: 5px 10px;
  background-color: #eee;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
}

.account-header-container span:first-child {
  font-weight: 600;
  color: #001949;
}

#line-graph-container {
  -webkit-box-shadow: 0px 0px 15px #e4e3e3;
  box-shadow: 0px 0px 15px #e4e3e3;
  padding: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
}

.summary-info {
  background-color: rgba(0, 0, 0, 0.045);
  padding: 20px 10px;
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.input-container {
  width: 69%;
}

.image-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.095);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.password-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .account-container {
    width: 100%;
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 15px;
  }
  .account-container .summary-info {
    width: 100%;
  }
  .account-container .input-container {
    margin-top: 20px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0px;
    padding: 0px;
  }
  .account-container .password-container {
    margin-top: 0px;
    width: 100%;
  }
}

.edit-inputs {
  border: 1px solid #ced4da;
  width: 30%;
  height: 50px;
  border-radius: 5px;
  padding: 10px;
  margin-right: 3.33%;
  margin-bottom: 15px;
}

.inputs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-items: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.info-status-container {
  padding: 0 5px;
  border-radius: 4px;
  height: max-content;
  font-size: 92%;
}

.select-field {
  width: 30%;
  margin-bottom: 15px;
  margin-right: 3.33%;
}

.defb-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 3px;
  padding: 6px 0px;
}
.defb-container .card-container,
.HandT {
  padding: 0px 20px;
}

.card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  border-radius: 8px;
  padding: 40px 20px;
  margin-bottom: 10px;
}

.card-container .card-donut-container,
.card-container .card-info-container {
  padding: 0px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
}
/* .defb-container .card-container .card-donut-container,
.defb-container .card-container .card-info-container {
  flex: 1;
} */
/* .defb-container .card-container .card-donut-container {
  justify-content: flex-end;
} */

.card-container .card-donut-container {
  width: 40%;
  /* margin-bottom: 20px; */
}

.card-container .card-donut-container .donut-container,
.card-container .card-donut-container .donut-info-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 44%;
  font-size: 2.5em;
  font-weight: bold;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.card-container .card-info-container {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card-container .card-info-container .card-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 5px 0px;
  font-size: 15px;
}

.defb-container .card-container .card-info-container .card-info {
  justify-content: flex-end;
}

.card-container .card-info-container .card-info .info-status-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center !important;
  width: 61.2px;
  height: 18.6px;
  color: white;
}

.defb-container
  .card-container
  .card-info-container
  .card-info
  .info-status-container {
  justify-content: flex-end;
  /* width: 18%; */
}

.card-container .card-info-container .card-info .info-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 82%;
  padding: 0px;
  padding-left: 10px;
}

.assess {
  width: 100%;
  margin: 10px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.09);
  padding: 10px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
}

.assess:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.19);
}

.assess-list {
  width: 100%;
}

#no-assessments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 65vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #eee;
}

#no-assessments span {
  font-weight: 600;
}

#graph-and-stats-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 20px 0px 0px;
}

#graph-and-stats-container #line-cotainer {
  width: 68.5%;
}

.stats-container {
  height: 48%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 15px #e4e3e3;
  box-shadow: 0px 0px 15px #e4e3e3;
  border-radius: 8px;
}

.stats-container hr {
  background-color: #eee;
  height: 1px;
  width: 80%;
}

.stats-container span:first-child {
  font-size: 20px;
  margin-bottom: 10px;
}

.stats-container span:last-child {
  font-weight: 600;
  font-size: 35px;
}

#stats-main-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 30%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
/*# sourceMappingURL=styles.css.map */

@media only screen and (max-width: 991px) {
  .fullDiv .backIcon {
    margin-top: 10px;
  }
  .card-container .card-info-container .card-info .info-container {
    width: 80%;
  }
  .card-container .card-info-container .card-info .info-status-container {
    font-size: 13px;
  }
  .assess-container .MuiTabs-flexContainer {
    flex-wrap: wrap;
  }
  .assess-container
    .MuiTabs-scroller.MuiTabs-fixed
    .MuiTabs-flexContainer
    button {
    padding: 8px 10px !important;
    min-width: 100px !important;
    max-width: 110px;
  }
}

@media only screen and (max-width: 600px) {
  .edit-inputs {
    width: 100%;
    margin-right: 0px;
  }
  .btn {
    width: 100% !important;
  }
  .card-container .card-info-container .card-info .info-status-container {
    font-size: 12px;
  }
  .card-container .card-info-container .card-info .info-container {
    font-size: 14px;
  }
  .tech-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .card-container .card-donut-container,
  .card-container .card-info-container {
    width: auto;
    max-width: 100%;
    padding: 0px 5px;
  }
  .card-container {
    flex-wrap: wrap;
  }
}
.assess-container .MuiTabs-flexContainer {
  column-gap: 5px;
  grid-column-gap: 5px;
    column-gap: 5px;
}
.assess-container .MuiTabs-flexContainer button {
  margin: 0px !important;
  flex: 1 0 15% !important;
}
