.orgSett .orgMainParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: 1;
}
.sideUserOrg {
  flex: 0 0 24%;
  max-width: 24% !important;
}
.orgSett .sideUserOrg {
  flex: 0 0 100%;
  max-width: 100% !important;
}
.orgSett.orgTable {
  flex: 0 0 75%;
  max-width: 75% !important;
}
.orgSett .newSet > div {
  width: 100%;
}
@media (max-width: 1100px) {
  .orgSett .orgTable .tableCover .MuiPaper-root.MuiTableContainer-root {
    max-width: 77vw;
  }
  .orgSett .sideUserOrg .MuiPaper-root {
    /*  */
    margin: 30px auto 0 auto;
  }
  .orgSett .sideUserOrg {
    flex: 0 0 100%;
    max-width: 100% !important;
  }
  .orgSett .orgTable {
    flex: 0 0 100%;
    max-width: 100% !important;
  }
  .orgSett .orgMainParent {
    flex-wrap: wrap;
  }
}
.orgSetting .changeBtn {
  display: none;
}
