.MuiAppBar-colorPrimary {
  color: initial !important;
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 10px 0;
}
.MuiTabs-flexContainer {
  margin: 10px 0 0 0;
  justify-content: space-around;
  flex-wrap: wrap;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
  flex: 1 0 22%;
  margin-bottom: 5px;
}
.MuiTabs-flexContainer{
  grid-column-gap: 5px;
    column-gap: 5px;
}
.tabsContent {
  padding: 0 !important;
}
.grpCardDivIn {
  display: flex;
  align-items: center;
}
/* .grpCardDivIn p {
  margin-bottom: 0;
  margin-right: 10px;
} */
.grpCardDiv .signOutbtnS {
  margin-bottom: 0;
}
.grpCardDivIn p {
  margin-bottom: 0;
  margin-right: 20px;
  font-weight: 600;
}
.grpCardDivIn .MuiInputBase-root.MuiInput-root.MuiInput-underline {
  border: 1px solid #116a9a;
  border-radius: 3px;
  font-size: 14px;
  text-transform: capitalize;
  padding: 5px 12px;
  transition: all 0.5s ease 0s;
}
.grpCardDivIn .MuiInputBase-root.MuiInput-root.MuiInput-underline:before,
.grpCardDivIn .MuiInputBase-root.MuiInput-root.MuiInput-underline:after {
  display: none;
}
.grpCardDivIn .MuiInputBase-input {
  padding: 2px 0;
  font-size: 14px;
  color: #116a9a;
  /* font-family: Montserrat; */
  font-weight: 500;
}
.grpCardDivIn .MuiSelect-icon {
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 25px !important;
  color: #116a9a;
}
.flexGraph.als .GraphBG {
  margin-top: 0;
}
.detpat .GraphBG {
  margin-top: 10px;
}
.detpat {
  margin-bottom: 10px;
}
.detpat .GraphBG {
  margin-bottom: 0px;
}
/* #menu- .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  top: 130px !important;
} */

@media (max-width: 576px) {
  .grpCardDiv button {
    margin-bottom: 0px !important;
  }
  .rightGrp {
    align-self: baseline;
  }
  .assess-container .MuiTabs-flexContainer {
    margin: 0;
  }
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
    flex: 1 0 49%;
  }
}
