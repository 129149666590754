.bg {
	background: #116a9a;
	min-height: calc(100vh - 168px);
}

.selectUserM {
	max-width: 659px;
	width: 100%;
	padding: 4px 15px;
	height: 47px;
	margin-bottom: 19px;
	background: #ffffff;
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	border: nonemax-height;
}

.optionUserM {
	display: inline block;
	/* background-color: #116a9a !important;
  background: #116a9a;
  color: #fff; */
}

.screenExpand {
	/* Commented out */
	/* flex:1; */
	flex: 0 0 94%;
	/* transition: "all 0.10s ease", */
}

/* .fixScreenExpand {
	flex: 0 0 94% !important;
} */

.checkData {
	background: #116a9a !important;
	background-color: #116a9a;
	display: inline-block;
	padding: 10px 15px;
	border-radius: 6px;
}

.SortBY {
	width: 152px;
	margin: 20px;
	padding: 5px;
	border: 0px;
	border-radius: 11px;
}

.checkData {
	position: absolute;
	left: 0;
	top: 70%;
	background: #ffffff;
	z-index: 99999;
	right: 0;
}

tbody tr th.MuiTableCell-root:nth-child(4)::before {
	right: 0;
	position: absolute;
	font-family: "Material Icons";
	content: "";
	-webkit-font-feature-settings: "liga" 1;
	-moz-font-feature-settings: "liga" 1;
	font-feature-settings: "liga" 1;
}

tbody tr th.MuiTableCell-root:nth-child(4) {
	position: relative;
}

/* @media (max-width: 1024px) {
  .inviteButton {
    margin: 32px 0 0 0;
  }
} */

@media only screen and (min-width: 1920px) and (max-width: 2800px) {
	.screenExpand {
		flex: 0 0 96.5% !important;
	}
}

@media (min-width: 1400px) {
	.screenExpand {
		flex: 0 0 95%;
	}
}

@media (max-width: 1100px) {
	.screenExpand {
		flex: 0 0 89%;
	}

	.card-donut-container .donut-container {
		margin-right: 6px;
		min-width: 100px;
	}
}