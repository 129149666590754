.orgDashboard .smallCard {
  flex: 0 0 33.333%;
  height: 100px;
}
.orgBox {
  justify-content: space-evenly;
}

.orgDashboard .bigCard {
  width: 1066px;
  margin: 20px auto;
  height: 503px;

  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
}
.orgDashboard .tagOrg {
  width: 357px;
  height: 37px;
  /* font-family: Montserrat; */
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #130c2d;
  padding: 30px 0px 0px 30px;
}
.orgStart {
  margin-top: 80px;
}
.GraphBG {
  background-color: #ffffff;
  /* margin-top: 10px; */
  margin-bottom: 10px;
  border-radius: 4px;
}
.graphbgMainR.barChartSpecial .GraphBG {
  margin-bottom: 0px !important;
}
.barChartSpecial .GraphBG > div {
  background-color: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.barChartSpecial .GraphBG {
  background-color: transparent;
}
.graphbgMainR.barChartSpecial {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  margin-bottom: 10px;
  row-gap: 5px;
  justify-content: center;
}
.GraphBG > h1 {
  background-color: white;
  margin: 0px;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  color: grey;
  padding: 8px 0px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
