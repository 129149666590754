.details .MuiDialog-paperWidthSm {
  max-width: 600px;
  border-radius: 3px;
  width: 50%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.inviteModalStart input {
  width: 198px;
  height: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #130c2d;
}

.AddUserDialog .MuiDialog-paperWidthSm {
  max-width: 1000px;
  border-radius: 10px;
  width: 70%;
  height: 80% !important;
  overflow: auto;
  /* height: auto !important; */
  /* min-height: 60% !important; */
}

.AddUserDialog .MuiDataGrid-root {
  height: 90% !important;
}

.super-admin-invite-org-container {
  position: static !important;
}

.appDetailBannerImgClass img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.appDetailBannerImgClass {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.bannerImageInputParent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.bannerImageBgContainer {
  padding: 3px;
  border: 1px solid #c5c5c5;
  border-radius: 3px;

}

.bannerImageBgContainer img {
  border-radius: 3px;
}

.detailsModalForm .bannerImageInputParent label {
  cursor: pointer;
  color: rgb(17, 106, 154);
  background: rgb(255, 255, 255);
  border-radius: 3px;
  font-size: 14px;
  text-transform: capitalize;
  padding: 5px 15px;
  border: 1px solid rgb(17, 106, 154);
  transition: all 0.5s ease 0s;
  width: auto;
  margin-bottom: 0px;
  font-weight: 500;
}

.detailsModalForm label {
  font-weight: 600;
  width: 100%;
  margin-bottom: 0px !important;
}

.detailsModalForm label input,
.detailsModalForm label textarea {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 10px;
  border-radius: 10px;
  border: none;
  width: 100%;
  margin-top: 5px;
  padding: 10px 15px;
}

.detailsModalForm p {
  font-weight: 500;
}

.addEditModalFlex {
  display: flex;
  /* gap: 10px; */
  flex-wrap: wrap;
  /* margin-bottom: 10px; */
}

.addEditFlexNoEditContainer {
  display: flex;
  /* gap: 8px; */
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.inputsEditAddDetail{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.detailsModalForm .appImageUploadClass label {
  cursor: pointer;
  color: rgb(17, 106, 154);
  background: rgb(255, 255, 255);
  border-radius: 3px;
  font-size: 14px;
  text-transform: capitalize;
  padding: 5px 15px;
  border: 1px solid rgb(17, 106, 154);
  transition: all 0.5s ease 0s;
  width: auto;
  margin-bottom: 0px;
  font-weight: 500;
}

.appImageUploadButtonsClass {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.appImageUploadButtonsClass .primaryClass {
  background-color: rgb(17, 106, 154);
  color: #ffffff;
  border-radius: 3px;
  font-size: 14px;
  text-transform: capitalize;
  padding: 5px 15px;
  border: 1px solid rgb(17, 106, 154);
}

.appImageUploadButtonsClass .secondaryClass {
  color: rgb(17, 106, 154);
  background-color: #ffffff;
  border: 1px solid rgb(17, 106, 154);
  border-radius: 3px;
  font-size: 14px;
  text-transform: capitalize;
  padding: 5px 15px;
}

.appImageUploadClass {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  margin: 20px 0;
  width: 100%;
}

.addEditModalcloseBtn {
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 500;
  margin-left: auto;
  display: flex;
}

.whatsNewBullets{
  max-height: fit-content;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 10px;
    border-radius: 10px;
    border: none;
    width: 100%;
    margin-top: 5px;
    /* padding: 10px 15px; */

}

.textAreaDescription{
  max-height: fit-content;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.25) 2px 2px 10px;
    border-radius: 10px;
    border: none;
    width: 100%;
    margin-top: 5px;
    padding: 10px 15px;
}
.ql-toolbar.ql-snow {
  border-bottom: 1px solid black !important;
  border: none;
}
.ql-container.ql-snow {
  border:none !important;
}
.addEditFlexNoEdit ul {
  padding: 0 20px;
  margin: 0;
}

.addEditFlexNoEdit li {
  font-weight: 500;
}

.addEditFlexNoEdit {
  display: flex;
  flex-basis: 100%;
  width: 100%;
  justify-content: flex-start;
  gap: 5px;

}

.addEditFlexNoEdit label {
  width: auto;
  align-items: center;
  margin: 0;

}

.addEditFlexNoEditContainerInner {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}

.addEditFlexNoEditDescription {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1 1 70%;
  align-items: center;
  padding: 10px;
}

.addEditFlexNoEditVersion {
  display: flex;
  /* flex-basis: 100%; */
  /* width: 100%; */
  justify-content: flex-end;
  gap: 5px;
}

.uploadedImageContainer {
  display: flex;
  align-items: flex-start;
  max-width: 520px;
  overflow: auto;
  overflow: auto;
  gap: 10px;
  padding:20px;
}


.uploadedImageContainer p {
  margin: 0 !important;
}

.addImageModalcloseBtn {
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 500;
  /* margin-left: auto; */
  display: flex;
}

.modalFileSelectorInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}