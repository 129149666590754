.headerBar {
  background: #fff;
}
.topnavdivlist {
  background: #116a9a;
  border-radius: 3px 0px 0px 0px;
  position: fixed;
  z-index: 5;
  padding-bottom: 10px;
  /* height: 80px; */
}

.screenExpand .topnavdivlist {
  padding-right: 10px;
}
.topnavdivlist ul {
  margin-bottom: 0px !important;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 16px;
  padding-right: 16px; */
  width: 100%;
  display: flex;
}
/* .topnavdivlist .listHeaderBar .listHeaderBar1 {
  flex: 0 0 30%;
} */

.topnavdivlist .listHeaderBar .listHeaderBar1 h3 {
  color: white;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 0px;
  /* Commented */
  /* white-space: nowrap; */
}
/* .topnavdivlist .listHeaderBar .listHeaderBar2 {
  flex: 0 0 30%;
} */
.subscDiv .dropdown.accountIcon {
  width: auto;
  min-width: 255px;
}
.topnavdivlist .listHeaderBar .listHeaderBar3 {
  color: white;
  /* justify-content: center !important; */
  font-size: 18px;
  margin-top: 7px;
  white-space: nowrap;
  align-items: center;
  /* flex: 0 0 15%; */
  max-width: fit-content;
}
.listHeaderBar3 {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  white-space: nowrap !important;
  column-gap: 10px;
  flex: 0 0 33%;
}

.changeBtn {
  margin: 7px 2px 0px 2px;
}
.personAddBtn {
  background-color: #116a9a;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 4px 10px;
  border-radius: 3px;
}
.changeBtn .dropdown {
  top: 100%;
}
.changeBtn .dropdownMain ul {
  max-height: 290px;
  overflow-y: auto;
}
.listHeaderBar {
  list-style: none;
  padding-inline-start: 0px;
}
.inputHeaderBar {
  height: 35px;
  border-radius: 3px;
  padding: 2px 13px 0px;
}
.searchbox {
  flex: 1;
  width: 86%;
}
.adminSchoolsearchIcon {
  /* position: absolute; */
  /* top: 1em;
  right: 1.5em; */
  padding: 10px;
  cursor: pointer;
}
.seacrhBar {
  position: relative;
}

.orgSelectionDropDown {
  height: 2em;
  margin-top: 1.5em;
  outline: none;
}
.topnavdivlist .headerBarIcon {
  color: white;
  font-weight: 50px;
  padding-right: 8px;
  max-width: 35px !important;
  margin-left: 10px !important;
  margin-right: 10px;
}
.topnavdivlist ul .firtLitop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topnavdivlist ul .firtLitop {
  flex-direction: column;
  /* align-items: flex-start; */
}
li.firtLitop .changeBtn .dropdownMain .dropdown {
  left: 0;
}
li.firtLitop .changeBtn .dropdownMain .dropdown::before {
  left: 0;
}
ul.ChangeOrganization {
  overflow-y: auto;
  max-height: 300px;
  height: 100%;
}
.over .headerUl {
  padding: 0px 20px;
}
.over.group .headerUl {
  padding: 0px 32px;
}
.learnsuite__logo {
  width: 100%;
  padding-top: 8px;
}
.learnsuite__logo img {
  width: 100%;
  max-width: 210px;
}
.learnsuite {
  flex: 0 0 33%;
  text-align: center;
}
/* .sideNav .MuiListItem-button {
  padding: 0px 4px !important;
} */
.screenExpand .topnavdivlist .listHeaderBar .listHeaderBar1 h3 {
  margin-left: 25px;
}

@media (max-width: 1024px) {
  .topnavdivlist .listHeaderBar .listHeaderBar1 h3 {
    font-size: 25px;
  }
  .topnavdivlist .listHeaderBar .listHeaderBar1 h3{
    font-size: 26px;
  }
  .over .headerUl {
    padding: 0px !important;
  }
  /* .topnavdivlist .headerBarIcon {
    margin: 0px 20px;
  } */
  /* .topnavdivlist {
		width: 76% !important;
	} */
  .fixtopnavdivlist ul {
    width: 98% !important;
  }
  .listHeaderBar1 .MuiBreadcrumbs-ol {
    font-size: 14px;
  }
  .topnavdivlist ul > li {
    width: 100%;
  }
  .topnavdivlist .listHeaderBar .listHeaderBar3 {
    text-align: right;
    /* white-space: nowrap; */
    white-space: pre-line;
    /* margin: 0 20px; */
  }
  .headerBar {
    background: transparent;
  }
}
@media (max-width: 900px) {
  .topnavdivlist .listHeaderBar .listHeaderBar3 {
    white-space: none !important;
    white-space: pre-line !important;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .topnavdivlist ul {
    padding-left: 0;
    padding-right: 0;
  }
  /* .fixtopnavdivlist {
    Commented 
width: 88% !important;
  } */
  .fixtopnavdivlist ul {
    width: 100% !important;
  }
}

@media (min-width: 568px) and (max-width: 767px) {
  .topnavdivlist .listHeaderBar .listHeaderBar1 h3 {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .topnavdivlist {
    position: static;
    width: 100% !important;
  }
  .topnavdivlist {
    /* width: 95% !important; */
    margin: 0 auto;
  }
  .listHeaderBar {
    flex-wrap: wrap;
  }
  .topnavdivlist .listHeaderBar .listHeaderBar1,
  .topnavdivlist .listHeaderBar .listHeaderBar2 {
    flex: 0 0 100%;
    text-align: left;
    font-size: 14px;
    margin: 10px 0 0 0 !important;
  }

}

@media (max-width: 1024px) {
  .topnavdivlist .listHeaderBar .listHeaderBar1 {
    flex: 0 0 33%;
  }
  .topnavdivlist ul {
    padding-left: 0px;
  }
  .screenContract .topnavdivlist {
    width: 75% !important;
  }
  .screenExpand .topnavdivlist {
    width: 90% !important;
  }
  .over.group .headerUl {
    padding: 0px 0px;
  }
}

@media (max-width: 767px) {
  .topnavdivlist .listHeaderBar .listHeaderBar3 {
    justify-content: flex-start !important;
    margin-left: 0px !important ;
    flex: 0 0 100%;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
  }
}
/* @media screen and (max-width: 460px) {
	.topnavdivlist .listHeaderBar .listHeaderBar3 {
		margin: 10px 0 0 0 !important;
	}
} */

@media (max-width: 768px) {
  .listHeaderBar3 .ChangeOrganization .dropdown.accountIcon {
    width: 250px;
  }
}

@media (max-width: 880px) {
  /* .topnavdivlist .listHeaderBar .listHeaderBar3 {
    flex: 0 0 6%;
  } */
}
@media (max-width: 767px) {
  .screenContract .topnavdivlist,
  .screenExpand .topnavdivlist,
  .topnavdivlist {
    width: 100% !important;
  }
  .topnavdivlist .listHeaderBar .listHeaderBar1 h3 {
    margin-left: 0px !important;
  }
}
