.basicMsg {
  font-size: 28px;
  width: 470px;
  height: 160px;
  box-shadow: 0px 0px 2px 2px lightgrey;
  text-align: center;
  margin: 20px auto;
  color: #116a9a;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.textSpan {
  margin: auto;
}
