.Footer {
  width: 100%;
  background: #0a3852;
  color: #fff;
  z-index: 10;
  position: sticky;
  padding-top: 10px;
  /* bottom: 0; */
}

.Footer h2 {
  font-weight: 600;
  color: #fff;
  font-size: 12px;
  margin: 0;
  padding: 6px 0px;
}

.Footer P {
  font-weight: 400;
  color: #fff;
  padding: 6px 0px;
  font-size: 10px;
  margin: 0;
}

.footerEnd {
  width: 60%;
  text-align: center;
  border-top: 1px solid #485d68;
  width: 100%;
  gap: 40px;
  padding: 0px 15px;
}

.colOne p {
  cursor: pointer;
}

.modelIcon2 {
  cursor: pointer;
}

.colOne p:hover {
  text-decoration: underline;
  color: #0056b3;
}

.privacyContent h1 {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

.privacyContent h3 {
  text-decoration: underline;
  font-weight: 600;
  font-size: 18px;
  margin-top: 30px;
}

.privacyContent h2 {
  text-decoration: underline;
  font-weight: 600;
  font-size: 18px;
  margin-top: 30px;
  letter-spacing: 0.5px;
}

.privacyContent .flexDiv {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: right;
  gap: 20px;
}

.privacyContent .flexDiv div:first-child {
  text-align: left;
}

.privacyContent .flexDiv div:first-child p {
  margin-bottom: 35px;
  position: relative;
}

.privacyContent .flexDiv div:first-child p:last-child:before {
  border-top: 1px solid #000;
  position: absolute;
  left: 0;
  content: "";
  width: 250px;
  top: -10px;
}

.privacyContent .flexDiv div:first-child p:last-child:after {
  border-bottom: 1px solid #000;
  position: absolute;
  left: 0;
  content: "";
  width: 250px;
  bottom: -28px;
}

.privacyContent {
  font-weight: 500;
  line-height: 28px;
  font-size: 16px;
}

.privacyContent p {
  margin: 0;
}

.privacyContent ol {
  padding-left: 18px;
  margin-top: 20px;
}

.privacyContent li {
  padding-left: 20px;
}

.subtitle {
  text-align: center;
  margin: 20px auto;
}

@media (max-width: 400px) {
  .footerEnd P {
    width: 100%;
    text-align: start;
  }

  .footerEnd {
    padding: 0px 15px;
    gap: 0px;
  }
}

.Footer .col-lg-3 {
  flex: 20%;
  max-width: 20%;
}

@media (max-width: 992px) {
  .row.footerEnd {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .Footer .col-lg-3 {
    flex: 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .Footer .col-lg-3 {
    flex: 100%;
    max-width: 100%;
  }
}
