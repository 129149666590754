.msb {
  background-color: #fff;
  position: relative;
  left: 0;
  top: 0;
  right: auto;
  bottom: 0;
  height: auto;
  min-height: 100vh;
  z-index: 1;
}
.navbar {
  margin-left: 0;
  /* width: 300px; */
  position: relative;
}
.icon {
  margin-right: 15px;
}
.sidelogo {
  max-height: 20px;
}
.navbar-header {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 20px;
  background: #fff;
}
.navblist .panel {
  border: 0 none;
  box-shadow: none;
  margin: 0;
  background: inherit;
}
.admin-list h2 img {
  max-width: 130px;
}
.num {
  display: flex;
  color: #130c2d;
  text-align: left;
  align-items: center;
  margin-top: 1rem;
  /* width: 173px; */
  height: 48px;

  /* font-family: Montserrat; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding-left: 9px;
}
.sideNavExpand {
  position: relative;
  flex: 0 0 20%;
  max-width: 20%;
}
.screenContract {
  flex: 0 0 80%;
  max-width: 80%;
}
.sideNavContract {
  position: relative;
  flex: 0 0 6%;
}
.MuiDrawer-paperAnchorDockedLeft {
  border: unset !important;
}
.admin-list p {
  text-align: center;

  /* font-family: Montserrat; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #116a9a;
}
.admin-list .subTopicLi {
  /* width: 32px; */
  height: 35px;
  align-items: center;
  text-align: start;
  list-style: none;

  /* font-family: Montserrat; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #130c2d;
}
.drawerfixed .MuiListItem-button:hover .MuiTypography-body1 {
  color: #ffffff;
}
.drawerfixed .MuiListItem-button:hover {
  color: #ffffff;
  background: #116a9a;
}
.drawerfixed .MuiListItem-button:hover .noImgOrg {
  color: #fff !important;
  border-color: #ffffff !important;
}
.navblist li:hover {
  color: #fff;
  cursor: pointer;
  width: 253px;
  height: 48px;
  background: linear-gradient(92.72deg, #08496f 0%, #116a9a 83.92%);
  border-radius: 15px;
}
.navblist .subTopic li:hover {
  color: #fff;
  cursor: pointer;
  width: 200px;
  height: 35px;
  background: linear-gradient(92.72deg, #08496f 0%, #116a9a 83.92%);
  border-radius: 15px;
}

.side-menu {
  width: 100%;
}

.admin-list .buttonList {
  position: absolute;
  left: 97px;
  bottom: -145px;
}
.admin-list .signoutIcon {
  padding-right: 5px;
}
.mainLableside {
  margin-bottom: 20px;
}
.menuItems {
  display: none;
}

.hdriconbar1.MuiSvgIcon-root {
  display: none;
  color: #fdc400;
  width: auto;
  height: auto;
  position: absolute;
  z-index: 11;
  top: 20px;
  left: 30px;
  cursor: pointer;
}
.MuiSvgIcon-root,
.hdriconbar1 {
  width: 28px !important;
  height: auto !important;
  font-size: 30px !important;
}
.drawerfixed ul.MuiList-root.MuiList-padding .MuiSvgIcon-root {
  max-height: 30px !important;
}

.drawerfixed {
  width: 20%;
  padding: 0px 12px;
}

.sideNavExpand .signOutbtn {
  padding: 0 !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
  background: inherit !important;
}

.sideNavExpand .signOutbtn:hover {
  color: #fff;
  background: inherit !important;
}
.signOutbtn .MuiSvgIcon-root {
  width: 30px !important;
  height: auto !important;
  font-size: 30px !important;
  margin-right: 8px !important;
}

.absdownArr {
  position: absolute;
  top: 47px;
  right: -30px;
  margin: auto;
  text-align: center;
  color: #fff;
  z-index: 999;
  cursor: pointer;
}
.showNavParentRoot {
  position: fixed;
  z-index: 10;
  height: 100%;
  background: #ffffff;
}
.absdownArrOpen {
  position: absolute;
  top: 47px;
  right: 0px;
  margin: auto;
  text-align: center;
  color: #fff;
  z-index: 999;
  cursor: pointer;
}
.dropdwnicon {
  background-color: #116a9a;
  color: #fff;
  height: 47px !important;
  width: 30px !important;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}
.dropdwniconOpen {
  background-color: #ffffff;
  color: #116a9a;
  height: 47px !important;
  width: 30px !important;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
}
/* //Collapsedpart */
.msb .logoCollapsed {
  max-width: 70px;
  margin-top: 65px;
  margin-bottom: 19px;
}
.navCollapsed {
  /* width: 100px; */
  height: 100vh;
}
.navCollapsed ul {
  padding-inline-start: 15px;
  position: absolute;
  top: 0px;
}
.navCollapsed li {
  list-style: none;
  padding-inline-start: 15px;
  max-height: 54px;
  height: 54px;
  padding: 7px 17px;
}
.navCollapsed li:hover {
  max-width: 70px;
  max-height: 54px;
  padding: auto;
  background: linear-gradient(259.54deg, #22acf1 41.77%, #116595 100.78%);
  border-radius: 15px 16px 16px 15px;
}
.navCollapsed .exitIcon {
  position: absolute;
  bottom: 60px;
  padding-inline-start: 37px;
}
.logoMobile {
  display: none;
}
.sideNav .noImgOrg {
  color: #116a9a;
  border-color: #116a9a;
  font-size: 14px !important;
  display: flex !important;
}

.downloadSimdashButtonContainer{
  display: flex;
  flex-direction: column;
margin-top: 180px;
gap: 10px;
}
.downloadSimdashLauncherIcon{
  width: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.downloadSimdashTitle{
  color:#116595;
font-weight: bold;
font-size: 18px;
margin-left: 7px;
margin-bottom: 0;
}
.downloadButtonIcon{
 margin-bottom: 15px;
}
.downloadButtonIcon svg{
  fill: #116595 !important;
}
.downloadButtonIcon:hover{
  background-color: #116595;

}

.downloadButtonIcon:hover svg{
  fill: #ffffff !important;
}

.downloadButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.downloadButton button {
  width: 100%;
  max-width: 100%;
}


@media only screen and (min-width: 1920px) and (max-width: 2800px) {
  .sideNavContract {
    flex: 0 0 3.5% !important;
  }
}
@media (min-width: 1400px) {
  .sideNavContract {
    flex: 0 0 5%;
  }
}

@media (max-width: 1200px) {
  .num {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .sideNavExpand {
    flex: 0 0 auto;
    flex-basis: 20%;
    max-width: 20%;
  }
  .screenContract {
    flex: 0 0 auto;
    flex-basis: 80%;
    max-width: 80%;
    padding: 0 20px;
  }
  .screenExpand {
    padding: 0 20px;
  }
}
@media (max-width: 991px) {
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .hdriconbar1.MuiSvgIcon-root {
    display: inline-block;
    z-index: 9999;
  }
  .hidden-md {
    display: none;
  }
  .showNavParentRoot {
    position: static;
  }
  .collapsed.msb {
    display: none;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .collapsed.is-expanded.msb#msb {
    position: relative;
    display: block;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    margin: 0;
    position: fixed;
    background-color: #2d313a;
    left: 0;
    right: 0;
    z-index: 999;
    padding-left: 0;
    top: 0px;
    color: #ffffff;
    max-width: 220px;
  }
  .msb .navblist {
    padding-top: 50px;
  }
  .adminlist {
    position: static;
  }

  .sideNavExpand {
    flex: 0 0 auto;
    flex-basis: 22%;
    max-width: 22%;
  }
  .screenContract {
    flex: 0 0 auto;
    flex-basis: 78%;
    max-width: 78%;
  }
  .drawerfixed {
    width: 22%;
    padding: 0px 12px;
  }
}

@media (max-width: 767px) {
  .sideNavExpand .MuiDrawer-docked {
    position: absolute;
    left: 0;
    right: 0;
  }
  .sideNavExpand {
    position: relative;
  }
  .sideNavExpand ul.MuiList-padding {
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.4);
    z-index: 9;
    padding: 10px 15px;
    border-radius: 4px;
    background-color: #fff;
  }
  .drawerfixed {
    width: 85% !important;
    padding: 0;
    margin: 0 auto;
  }
  .logoMobile {
    display: inline-block;
    max-width: 55px;
  }
  .sideNavExpand main {
    display: none;
  }
  .menuItems {
    display: flex;
    justify-content: space-between;
  }
  .sideNavExpand ul.MuiList-padding li:first-child {
    display: none;
  }
  .sideNavExpand {
    flex-basis: 100%;
    max-width: 100%;
  }
  .menuItems {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.739);
    box-shadow: 2px 2px 2px rgb(158 158 158 / 87%);
  }
  .menuItems .mobile__hamburger__icon {
    cursor: pointer;
  }
  .drawerfixed {
    position: static !important;
    height: auto !important;
    width: 100%;
  }
  .absdownArrOpen {
    display: none;
  }
  .d-flex.over {
    flex-wrap: wrap;
  }
  .screenContract {
    flex-basis: 100%;
    max-width: 100%;
  }
}
