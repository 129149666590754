.orgSelectionDiv .MuiCardContent-root:last-child,
.orgSelectionDiv .MuiCardContent-root {
  padding: 0 !important;
}
.paperGrid {
  max-width: 95%;
  width: 600px;
  text-align: center;
}
.orgSelectionDiv h3 {
  color: #116a9a;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
}
.orgSelectionDiv h3:after {
  position: absolute;
  bottom: -10px;
  content: "";
  border-bottom: 3px solid #116a9a;
  left: 0;
  right: 0;
  margin: auto;
  width: 40px;
}
.orgTabBtn {
  font-size: 15px;
}
.orgTabBtn.active {
  background-color: #116a9a;
  color: #fff;
}
.justify-content-space-between {
  justify-content: space-between;
}
.orgSelectionDiv .signOutbtn {
  width: 100%;
  padding: 2px 5px !important;
  font-weight: 600;
}
.grayOrg.pt-8 {
  padding-top: 8px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
}
.grayOrg.pb-8 {
  padding-bottom: 8px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
}
.spacingDot {
  padding: 0 5px;
  color: #fff;
  font-weight: 600;
}
.nameOrg.MuiTypography-root {
  font-size: 18px;
  font-weight: 600;
}
.parentOrgCrd {
  max-height: 325px;
  overflow-y: auto;
  padding: 0 6px;
}
