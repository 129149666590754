.headerWrapper {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding: 10px 40px;
  box-sizing: border-box;
  justify-content: center;
}
.BottomHeader {
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}

.headerLogo {
  width: 15%;
}
.headerLists {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: white;
}

.headerLists ul {
  color: white;
  display: flex;
  list-style: none;
  margin-bottom: 0;
}

.headerLists li {
  margin: 0 15px;
}

.headerOption {
  color: white;
  display: flex;
  align-items: center;
  font-weight: 600;
}

@media (max-width: 992px) {
  .headerLists ul {
    flex-wrap: wrap;
    flex-direction: column;
    padding-left: 0;
  }
  .headerLists li {
    margin: 0;
  }

  .headerLogo {
    width: 20%;

    margin-top: 8px;
  }
}
.logoutbtn {
  position: fixed;
  right: 25px;
  top: 10px;
}
