.subStart {
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  background: #116a9a;
}
.gridsubs {
  max-width: none;
  flex-basis: 32.7%;
}
/* #gridsubs > div {
  height: 100%;
} */
#gridsubs .MuiPaper-root.MuiCard-root {
  min-height: 370px !important;
}
.subscDiv .subStart {
  padding-left: 20px;
  padding-right: 20px;
}
.subscDiv .inviteButton{
  margin-bottom: 10px;
}
#gridsubs > div {
  height: 100%;
padding: 0;
margin: 0 auto;
justify-content: flex-start;
margin-bottom:10px;
}
@media (max-width:1365px) {
  .gridsubs {
    flex-basis: 32.5%;
  }
  #gridsubs .MuiPaper-root.MuiCard-root {
    min-height: 422px !important;
  }
}
@media (max-width:1050px) {
  .gridsubs {
    flex-basis:49%;
  }
  
}
@media (max-width: 1024px) {
  .gridsubs {
    max-width: none;
    flex-basis: 49%;
  }
  #gridsubs .MuiCardContent-root ul li span {
    padding: 0 1px;
  }
}
@media (min-width:800px) and (max-width:1000px){
  .subscDiv .subStart {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 767px) {
  .subStart {
    margin-top: 0px;
  }
}
@media (max-width: 568px) {
  .gridsubs {
    max-width: none;
    flex-basis: 100%;
  }
  #gridsubs .MuiPaper-root.MuiCard-root {
    min-height:auto !important;
  }
  #gridsubs > div {
    padding: 0;
    padding-bottom: 5px;
  }
}
