.tableHeading {
  margin: 0;
  padding: 5px 10px;
  background-color: #ffffff;
  color: #116a9a;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}
.modalFileSelectorInput {
  width: 100%;
  max-width: 659px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalFileSelectorInput input[type="file"] {
  padding: 7px 10px;
  height: 47px;
  margin-bottom: 19px;
  background: #ffffff;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25) !important
  ;
  width: 100%;
  max-width: 659px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.modalFileSelectorInput input[type="file"]::file-selector-button {
  padding: 5px 15px;
  border-radius: 10px;
  margin-right: 10px;
  border: none;
  background: rgb(17, 106, 154);
  color: #fff;
  cursor: pointer;
}
input[type="file"]::file-selector-button:hover {
  background: #fff;
  border: 1px solid rgb(17, 106, 154);
  color: rgb(17, 106, 154);
}
.simdashLauncherDetailModalContainer {
  display: flex;

  flex-direction: column;
  gap: 10px;
  height: 100%;
  max-width: 100%;
  width: 100%;
  max-width: 500px;
  padding: 0;
  overflow: auto;
  font-family: sans-serif;
}

.simdashLauncherDetailModalContainer .heading {
  flex: 1 1 30%;
}

.simdashLauncherDetailModalContainer .heading ul {
  padding: 0;
  margin: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 35px;
}
.simdashLauncherDetailModalContainer .heading ul li {
  display: flex;
  gap: 25px;
  list-style: none;
  font-weight: 600;
  font-size: 16px;
}

.simdashLauncherDetailModalContainer .dataContainer {
  flex: 1 1 70%;
}

.simdashLauncherDetailModalContainer .dataContainer ul {
  padding: 0;
  margin: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 40px;
}
.simdashLauncherDetailModalContainer .dataContainer ul li {
  display: flex;
  gap: 25px;
  list-style: none;
  font-weight: normal;
  /* font-size: 17px; */
}
.cancelButton {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  padding: 10px 0;
}
.cancelButton button {
  background-color: #116a9a;
  color: white;
  border: none;
}

.tableIconMenuContainerInner {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  border: 1px solid #1169a9;
  background: #fff;
  padding: 10px;
  justify-content: center;
  height: fit-content;
  z-index: 5;
}
.tableIconMenuContainerInner button {
  width: 100%;
  max-width: 200px !important;
  padding: 0 10px !important;
}
.tableIconMenuContainerInner button:last-child {
  width: 161px !important;
}
.simdashLauncherDetailModalContainer .detailModalContainerRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}
.detailModalContainerRow h6 {
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  max-width: 140px;
  text-align: left;
}
.detailModalContainerRow p {
  text-align: left;
}
.bg {
  position: relative;
}

.toastContainer {
  position: fixed;
  top: 68%;
  left: 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.MuiSnackbar-anchorOriginBottomCenter {
  position: static !important;
}
.MuiSnackbar-root {
  z-index: 0 !important;
}
.deleteModal {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.deleteModalBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.deleteModalBtnContainer .primaryClass {
  background-color: rgb(17, 106, 154);
  color: #ffffff;
  border-radius: 3px;
  font-size: 14px;
  text-transform: capitalize;
  padding: 5px 15px;
  border: 1px solid rgb(17, 106, 154);
}
.deleteModalBtnContainer .secondaryClass {
  color: rgb(17, 106, 154);
  background-color: #ffffff;
  border: 1px solid rgb(17, 106, 154);
  border-radius: 3px;
  font-size: 14px;
  text-transform: capitalize;
  padding: 5px 15px;
}

.deleteModalHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 5px; */
}
.addStoreAppBtn {
  color: rgb(17, 106, 154);
  background-color: #ffffff;
  border: 1px solid rgb(17, 106, 154);
  border-radius: 3px;
  font-size: 18px;
  text-transform: capitalize;
  padding: 5px 15px;
  max-width: 150px;
  /* font-weight: 600; */
}
.addStoreAppBtn:hover {
  color: #fff;
  background-color: #1169a9;
  border: 1px solid #fff;
}
.deleteModalcloseBtn {
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  margin-left: auto;
  display: flex;
}
@media screen and (min-width: 600px) {
  .MuiSnackbar-anchorOriginBottomCenter {
    transform: none !important;
  }
}
