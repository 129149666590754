.tableCover {
  background: #ffffff;
  border-radius: 3px;
  padding: 10px 30px;
  max-width: 100%;
  min-width: 95%;
  margin-bottom: 15px;
  margin-top: 10px;
  /* max-width: 100%; */
}
table .MuiTableCell-root {
  border-bottom: unset;
  padding: 10px 16px;
}
thead .MuiTableCell-head {
  color: #116a9a;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
}
tbody tr th,
tbody tr td {
  font-size: 18px !important;
}
.pageIcon {
  color: #116a9a !important;
  width: 35px !important;
  cursor: pointer;
}
.allButtons {
  background: linear-gradient(89.94deg, #1470a3 0.04%, #22acf1 97.73%);
  color: #ffffff;
  border-radius: 11px;
  border: 0px;
  padding: 5px 10px;
}
/* tbody tr:first-child {
  background-image: linear-gradient(
    180deg,
    #c2e6f9 0%,
    rgba(225, 225, 225, 0.07) 100%
  );
} */
tbody tr:first-child th:first-child {
  border-top-left-radius: 15px;
}
tbody tr th.MuiTableCell-root {
  text-align: center;
}

tbody tr:first-child td:last-child {
  border-top-right-radius: 15px;
}
.componentSec {
  padding: 135px 60px 0px;
}


.MuiPaper-elevation1 {
  box-shadow: unset !important;
}
.del {
  color: red !important;
}
.delIcon {
  padding: 10px;
  width: 48px;
  max-width: 100%;
}
.delIcon:hover {
  background-color: grey;
  border-radius: 50%;
  padding: 10px;
}
@media (max-width: 1024px) {
  .componentSec {
    padding: 120px 0px 0px;
  }
}
@media (max-width: 768px) {
  .tableCover {
    margin-top: 10px;
    padding: 10px 20px;
  }
}
@media (max-width: 767px) {
  .componentSec {
    padding: 0px;
  }
}
@media (max-width: 1024px) {
  .screenExpand .tableCover {
    width: 650px;
  }
}
