.titleCardcus {
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  flex: 50%;
  text-align: center;
  /* fontFamily: Montserrat, */
  color: #116a9a;
}
.tickBox {
  height: 20px;
  width: 20px;
}
.noCardcus {
  /* font-family: Montserrat; */
  /* font-weight: bold; */
  color: #878787;
  /* font-size: 42px; */
  font-size: 30px;
  flex: 23%;
  text-align: center;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  line-height: 1 !important;
}

@media (max-width: 1024px) {
  .titleCardcus {
    font-size: 19px;
  }
  .noCardcus {
    font-size: 24px;
  }
  .d-flex.over {
    justify-content: space-between;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .noCardcus {
    font-size: 18px !important;
  }
}
@media (max-width: 991px) {
  .titleCardcus {
    font-size: 18px;
  }
  .noCardcus {
    font-size: 21px;
  }
}
@media (max-width: 768px) {
  .titleCardcus {
    font-size: 15px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .mainCard {
    font-size: 20px !important;
  }
}
