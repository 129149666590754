.tableContainer {
  height: 100%;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  padding: 0 30px;
  background: #fff;
}
.tableContainer table {
  background-color: #fff;
  border-spacing: 0px !important;
  width: 100%;
  padding: 10px 20px;
  /* // height: 100%; */
  /* height: 45vh; */
  overflow: auto;
  margin: auto 0;
}

.tableContainer table .tableHeading {
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 3;
  position: sticky;
  background: #fff;
  border-bottom: 2px solid grey;
}
.tableContainer table .tableHeading th {
  color: #116a9a;
  padding: 10px 16px;
  font-size: 20px;
  font-weight: bold;
  min-width: 92px;
  white-space: nowrap;
}

.tableContainer table .tableBody {
  text-align: center;
  background: #fff;
  /* position: relative !important; */

  padding: 0px 15px;
}

table .tableBody tr td {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
}
table .tableBody tr td .actionBtn {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  gap: 10px;
}
table .tableBody tr td .iconContainer svg {
  color: #116a9a !important;
  cursor: pointer;
}
table .tableBody tr td .iconContainer {
  position: relative;
}
.MuiList-padding {
  padding: 0 !important;
}
.tableMenuItem:first-child {
  border-bottom: 1px solid black;
}

.tableMenuItem:nth-last-child(2) {
  border-bottom: 1px solid black;
}

@media screen and (max-width: 990px) {
  .tableContainer table {
    padding: 0;
  }

  .tableContainer table .tableBody tr td {
    padding: 10px 0;
  }

  .tableContainer table .tableBody tr td .actionBtn {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
