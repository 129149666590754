@media (max-width: 768px) {
  .makeStyles-marginTop-8 div.MuiGrid-grid-xs-5 {
    max-width: 60%;
    flex-basis: 60%;
  }
}
@media (max-width: 450px) {
  .headsection.MuiGrid-grid-xs-3 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .makeStyles-marginTop-8 .MuiGrid-grid-xs-5 {
    max-width: 65% !important;
    flex-basis: 65% !important;
  }
  /* .mobileFlex {
    flex-direction: column !important;
  } */
  .makeStyles-titleSubHeading-6 {
    font-size: 12px;
  }
  .mobileView.MuiGrid-grid-xs-5 {
    max-width: 65%;
    flex-basis: 65%;
  }
  .makeStyles-marginTop-8 div.MuiGrid-grid-xs-3 {
    max-width: 35%;
    flex-basis: 35%;
  }
}
