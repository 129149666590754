#codeblack-assess-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  /* background-color: #fff; */
  /* padding: 40px; */
  border-radius: 3px;
}
#codeblack-assess-container .col-lg-3 {
  max-width: 24%;
  padding: 0px;
}
.assess-card-container .card-item span {
  font-size: 13px;
}
/* .assess-card-container .card-item {
  margin: 10px 0px;
} */

@media (max-width: 840px) {
  .assess-card-container {
    justify-content: center;
  }
  #codeblack-assess-container {
    justify-self: center;
  }
}
.assess-card-container {
  background-color: #eee;
  margin-bottom: 5px;
  align-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  /* width: 236px;
  height: 250px; */
  max-width: 100%;
  background: #ffffff;
  border: 1px solid #116a9a;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

/* .card-item {
  display: flex;
  &:last-child {
    margin-bottom: 0px;
  }
  span {
    margin-bottom: 10px;
    &:first-child {
      width: 40%;
      background-color: green;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:last-child {
      width: 57%;
      padding-left: 10px;
    }
  }
} */
.card-item {
  margin: 10px 0px;
  /* margin-bottom:0px; */
}
.codeblack-assess-container .assess-card-container h4 {
  margin-bottom: 8px;
}
.card-item span:first-child {
  background: #438931;
  border-radius: 3px;
  /* padding: 4px 16px; */
  padding-bottom: 2px;
  color: #ffffff;
  display: inline-block;
  width: 61.5px;
  text-align: center;
}
.failed-card-item {
  background: "red";
}
.assess-card-container h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  color: #000000;
}
.cardAssmntInner {
  flex: 1 0 24%;
}
#no-situations-container {
  width: 100%;
  height: 80vh;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}
#codeblack-assess-container {
  grid-column-gap: 5px;
}

@media only screen and (max-width: 768px) {
  #codeblack-assess-container .assess-card-container {
    width: auto;
    height: auto;
  }
  .card-item span:first-child {
    padding: 2px 4px;
  }
  #codeblack-assess-container {
    grid-column-gap: 5px;
  }
  .cardAssmntInner {
    flex: 1 0 49.5%;
  }
}
@media only screen and (max-width: 400px) {
  .card-item span:first-child {
    font-size: 12px;
  }
}
