.detailsVrCode .MuiPaper-root.MuiDialog-paper {
    padding: 30px;
    position: relative;
}

.model-head {
    position: absolute;
    right: 8px;
    top: 6px;
    padding: 0;
}

.vrPassCodeParent {
    text-align: center;
}

.detailsVrCode h1 {
    margin-bottom: 30px;
}

.detailsVrCode p b {
    margin-top: 10px;
    display: inline-flex;
}