.lifeSupport {
	display: flex;
	flex-direction: column;
	margin: 130px 20px 0px;
}

.graphDiv {
	width: 100%;
	background-color: #fff;
	margin: 10px auto 0 auto;
	border-radius: 3px;
}
.spinnerDiv {
	height: calc(100vh - 130px);
	width: 70%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	color: #ffffff;
}
.onlyPrData{
	margin-top:10px;
}
.projectAnaGraphContainer div#line-graph-container {
    margin: 0 !important;
}
.projectAnaGraphContainer {
    margin-bottom: 10px;
}
.lifeSupportContent {
	display: flex;
	flex-direction: column;
	border-left: 2px solid white;
}

.lifeSupportCustomCard {
	display: flex;
	justify-content: space-between;
}

.sidesearchlist .MuiListItemText-primary,
.sidesearchlist .MuiTypography-colorTextSecondary {
	word-break: break-word;
}

@media (max-width: 1024px) {
	.lifeSupportCustomCard {
		flex-wrap: wrap;
		margin: 0 !important;
	}
	.lifeSupportContent {
		margin-right: 0 !important;
	}
	.sidesearchlist > li {
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: column;
	}
	.sidesearchlist .MuiListItemText-primary,
	.sidesearchlist .MuiTypography-colorTextSecondary {
		text-align: center;
	}
	.lifeSupport .d-flex.justify-content-between {
		/* width:97%; */
	}
	.lifeSupport {
		margin-left: 0;
		margin-right: 0;
	}

	.lifeSupportCustomCard .MuiCard-root {
		flex: 0 0 100% !important;
		justify-content: flex-start;
	}
}

@media (max-width: 767px) {
	.lifeSupport {
		margin: 20px 0px 0px;
	}
}

@media (max-width: 568px) {
	.lifeSupport .d-flex.justify-content-between {
		width: 100%;
		flex-wrap: wrap;
	}
}
