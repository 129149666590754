.signIn {
  display: flex;
  align-items: center;
  flex-direction: column;

  box-sizing: border-box;
  margin-top: 50px;
  justify-content: flex-start;
}

.signInFormDiv {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  background-color: #116a9a;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 10px lightgrey;
  width: 720px;
  max-width: 80%;
}

.formDiv {
  position: relative;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  background-color: #fff;
  margin-right: 0 !important;
  padding: 20px 0;
}

.signInForm {
  position: relative;
  display: flex;

  margin: 10px auto !important;
  width: 100% !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signInForm .MuiOutlinedInput-input {
  width: 100%;
}

.randomName {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100% !important;
}

.signInFormOptions {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(90% - 50px);
}
.randomNamep {
  text-align: center;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 5px;
}

.rememberMeOption {
  display: flex;
  align-items: center;
}

.registerButton {
  margin-top: 20px;
}

.RegisterInfo,
.signInEmail,
.signInPassword {
  margin-bottom: 30px;
}

.signInForm .MuiOutlinedInput-input {
  padding: 15px 10px;
}
.RegisterInfo,
.signInEmail,
.signInPassword {
  position: relative;
  width: calc(90% - 50px);
  height: 40px;
}

.signInForm .PrivateNotchedOutline-root-2 {
  top: -5;
}

.signInPassword .eyeicon {
  position: absolute;
  right: 15px;
  bottom: 0px;
  cursor: pointer;
}

.rememberMeOption input[type="checkbox"] {
  margin-right: 5px;
}

@media (max-width: 991px) {
  .signInFormDiv {
    width: 720px;
    max-width: 80%;
    flex-wrap: wrap;
    height: auto;
    overflow: visible;
  }
  .detailsDiv {
    width: 100%;
    flex: 0 0 100%;
  }
  .signInHeading {
    font-size: 1.75rem;
    padding-top: 15px;
  }
  .detailsDiv h2 {
    font-size: 22px;
    margin-bottom: 0;
    color: #ffffff;
  }
  .RegisterInfo .d-flex.flex-row {
    flex-wrap: wrap;
  }
  .RegisterInfo {
    height: auto;
    margin-bottom: 10px;
  }

  .MuiFormControl-root.MuiTextField-root.ml-2 {
    margin: 10px 0 0 0;
    margin-left: 0 !important;
  }
}

@media (max-width: 768px) {
  .signInFormOptions {
    flex-wrap: wrap;
  }
}
@media (max-width: 400px) {
  .RegisterInfo,
  .signInEmail,
  .signInPassword,
  .signInFormOptions {
    width: calc(90% - 15px);
  }
  .signInFormDiv {
    max-width: 88%;
  }
}
