.optionButton {
  /* d-flexjustify-content-center */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.vrCodePasscode {
  font-family: "Courier New", Courier, monospace !important;
  font-size: 22px !important;
  letter-spacing: 2px !important;
}

/* .MuiSvgIcon-root .tooltipIcon {
  width: 20px !important;
} */

@media (max-width: 1305px) {
  .optionButton {
    flex-direction: column;
  }
}

.vrCode {
  display: flex;
  flex-direction: column;
}

.vrCode p {
  font-size: 18px;
  padding: 0;
}

.vrCode .vrCodeData {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  gap: 10px;
}

@media (max-width: 985px) {
  .orgInfo p {
    font-size: 22px;
  }
}

@media (max-width: 580px) {
  .orgInfo p {
    font-size: 15px;
  }
}