.legends {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  color: black;
  background-color: white;
  width: 70%;
  margin: auto;
  padding: 20px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.legends .first {
  margin: 0px 10px;
  width: 30px;
  height: 10px;
  background-color: #5cb55b;
}

.legends .second {
  margin: 0px 10px;
  width: 30px;
  height: 10px;
  background-color: #ff4f4f;
}
@media (max-width: 1190px) {
  .legends {
    width: 100%;
    margin-top: 10px;
  }
}
