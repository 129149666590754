.signIn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  box-sizing: border-box;
}
.AuthBg {
  min-height: 100vh;
  background-color: #116a9a1a;
}

.signInFormDiv {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 720px;
  max-width: 80%;
  background-color: #116a9a;
  border-radius: 10px;
  box-shadow: 0px 0px 10px lightgrey;
}
.rememberMeOption label,
.forgotPassword div {
  margin: 0 !important;
  padding: 0 !important;
}

.forgotPassword div {
}
.signInButton {
  margin-top: 20px;
}
.formDiv {
  position: relative;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  flex: 1;
  background-color: #fff;
  margin-right: 0 !important;
}

.detailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content !important;
  flex: 0.7;
  width: 50%;
  background: #116a9a;
  padding: 20px;
  text-align: center;
  height: 100% !important;
}
.detailsDiv h2 {
  font-size: 22px;
  color: #ffffff;
}

.signInForm {
  position: relative;
  display: flex;

  margin: 40px auto !important;
  width: 100% !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signInForm .MuiOutlinedInput-input {
  width: 100%;
}

.randomName {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100% !important;
}

.signInHeading {
  font-size: 30px;
  text-align: center;
  width: 100%;
  color: #116a9a;
}

.signInFormOptions {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(90% - 100px);
}

.rememberMeOption {
  display: flex;
  align-items: center;
}
.registerButton {
  margin-top: 20px;
}

.signInmain .signInEmail,
.signInmain .signInPassword {
  margin-bottom: 35px;
}
.errorMsg {
  font-size: 11px;
  color: red;
  font-weight: 600;
}
a {
  color: unset;
}

.signInForm .MuiOutlinedInput-input {
  padding: 15px 10px;
}
.signInEmail,
.signInPassword {
  position: relative;
  width: calc(90% - 50px);
  height: 40px;
}

.signInForm .PrivateNotchedOutline-root-2 {
  top: -5px;
}
.signInForm .MuiInputLabel-formControl {
  top: -5px;
}
.signInPassword .eyeicon {
  position: absolute;
  right: 15px;
  bottom: 0px;
}

.horizontalLine {
  height: 5px;
  width: 50px;
  background-color: #116a9a;
  margin: 5px auto 0px auto;
}
.button1 {
  width: 160px;
  height: 40px;
  font-size: 20px;
  font-weight: 500;
  background-color: white;

  /* background-color: #116a9a; */
  color: #116a9a;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #116a9a;
}
.button1.active,
.button2.active {
  background-color: #116a9a;
  color: #fff;
}
.button2 {
  width: 160px;
  height: 40px;
  font-size: 20px;
  font-weight: 500;
  background-color: white;
  color: #116a9a;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  border: 1px solid #116a9a;
}
