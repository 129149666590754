.dropdownMain h3 {
  font-weight: 600;
  text-align: center;
  color: #116a9a;
  font-size: 20px;
  padding: 18px 0px;
  margin: 0;
  /* background: #f2f2f2; */
}
.dropdownMain {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.dropdownMain:hover .dropdown {
  display: flex !important;
}
.dropdown {
  display: flex !important;
}

.dropdown {
  position: absolute;
  right: 0;
  top: 116%;
  width: 450px;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 4px 0px #a1a1a1;
  -moz-box-shadow: 0 0 4px 0px #a1a1a1;
  box-shadow: 0 0 4px 0px #a1a1a1;
}

.dropdown .column1 {
  padding: 0px !important;
}
.dropdown .column {
  padding: 0px;
  margin: 5px 0px;
}
.accountIcon li {
  justify-content: center;
}
.dropdownMain ul li {
  width: 100%;
  display: flex;
  /* border-bottom: 1px solid #d8d8d8; */
  /* color: #000; */
  color: #116a9a;
  padding: 18px 18px;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  flex-direction: row-reverse;
  white-space: break-spaces;
  text-align: center;
  justify-content: center;
}
.ChangeOrganization li:hover {
  background: #fff !important;
  color: #116a9a !important;
}

.selectedChangeOrganization {
  /* background: #fff !important; */
  color: #116a9a !important;
}
.dropdownMain li:hover {
  background: #116a9a;
  color: #fff;
}
.topnavdivlist .dropdownMain ul {
  flex-direction: column;
  padding: 0;
  width: 100% !important;
}
.topnavdivlist ul.ChangeOrganization{
  justify-content: flex-start;
}
.userInfo {
  justify-content: center;
  width: 100%;
  padding-bottom: 5px;
  box-shadow: 0 4px 2px -2px #b3ccd9;
  min-height: 50px;
}
.userInfo .noImgOrg {
  border: 1px solid #116a9a;
  padding: 0px 12px;
  margin: 0 !important;
  margin-right: 6px !important;
  display: inline-flex;
  align-self: center;
  justify-self: center;
  white-space: initial;
}
.userInfo p {
  font-size: 10px;
  font-weight: 500;
  white-space: break-spaces;
  word-break: break-word;
  margin-bottom: 0px;
  color: #116a9a;
  max-width: 70px;
  /* text-align: start; */
}
/* .dropdownMain ul li:first-child {
  -webkit-box-shadow: inset 0 1px 2px 0px #d0cece;
  -moz-box-shadow: inset 0 1px 2px 0px #d0cece;
  box-shadow: inset 0 1px 2px 0px #d0cece;
} */
/* .dropdownMain ul li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
} */
.dropdownMain li .MuiSvgIcon-root {
  margin-right: 12px;
}
.dropdown:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  top: -10px;
  border-bottom: 10px solid #f2f2f2;
  right: 16px;
}
span .MuiBadge-anchorOriginTopRightRectangle {
  top: 5px !important;
  right: 18px !important;
}
.imgOfOrg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 0px !important;
}
.noImgOrg {
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
  color: #fff;
  margin: 0 auto;
  text-align: center !important;
  flex-direction: column;
  /* padding: 8px 15px; */
  margin-bottom: 0px;
  display: inline-flex;
  border: 1px solid #fff;
  justify-content: center;
  align-content: center;
  font-size: 20px !important;
  color: #fff;
  text-align: center !important;
}
.centerTxt .noImgOrg {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #462121;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  word-break: inherit;
  margin-right: 5px;
}
.dropdownMain li:hover .noImgOrg {
  color: #116a9a;
  border-color: #116a9a;
}

@media (max-width: 1024px) {
  .imgOfOrg {
    margin-left: 4px;
    margin-right: 4px;
  }
}

@media (max-width: 991px) {
  .dropdown:before {
    right: 3px;
  }
}
@media (max-width: 770px) and (min-width: 767px) {
  .sideNav .MuiListItem-gutters {
    margin-top: 6px;
  }
}

@media (max-width: 767px) {
  .dropdown:before {
    left: auto;
    right: 10px;
  }
  .dropdown {
    left: auto;
    right: 0;
    z-index: 9;
    max-width: 430px;
  }
}

@media (max-width: 500px) {
  .dropdown {
    max-width: 70vw;
  }
  .dropdown:before {
    display: none;
  }
}
@media (max-width: 400px) {
  .dropdown {
    left: 0;
    right: 0;
    margin: auto;
  }
  .dropdown .column {
    border-right: none !important;
    margin: 0px;
  }
  .userInfo {
    justify-content: center;
    border: 1px solid #116a9a;
    padding: 0px;
  }
  ul.ChangeOrganization {
    max-height: 335px !important;
  }
  .userInfo p {
    font-size: 9px !important;
  }

  ul.ChangeOrganization {
    display: flex;
    width: 100%;
    flex-direction: row !important;
  }

  .dropdownMain .dropdown {
    flex-wrap: wrap;
  }
  .dropdown.accountIcon.text-center.d-flex {
    flex-wrap: wrap;
    width: fit-content;
  }
}

.orgToolTip {
  position: static;
  display: inline-block;
  border-bottom: 1px dotted black;
  padding: 10px 5px;
  display: flex;
  height: 80px;
  /* align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse; */
}
.relatdiv {
  position: relative;
}
.absImg {
  position: absolute;
  left: -18px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 1px solid #ffffff;
  background-color: #116a9a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.orgToolTip .orgToolTiptext {
  /* visibility: hidden;*/
  display: none;

  background-color: black;
  color: #fff;
  text-align: center;

  border-radius: 6px;

  position: absolute;
  z-index: 5;
  left: -150px !important;
  height: 0;
}

.orgToolTip:hover .orgToolTiptext {
  /* visibility: visible; */
  display: block;
  padding: 5px 0;
  width: 120px;
  height: 77px;
}
.notificationText {
  align-self: center;
  padding: 18px !important;
}
div#notification .dropdown {
  flex-direction: column;
}
div#notification .dropdown ul {
  border-top: 1px solid #b3ccd9;
}
.ChangeOrganization {
  /* display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center; */
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: unset !important;
}

.ChangeOrganization .organizationListBlock {
  display: block;
  padding: 12px 6px;
}

@media (max-width: 820px) and (min-width: 768px) {
  .MuiMenu-paper.MuiPopover-paper {
    top: 320px !important;
  }
}
div#notification .dropdown ul .withNotification {
  justify-content: space-between !important;
}

.notificationList .noNotification {
  justify-content: center;
}
