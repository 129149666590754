.flexsupad {
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
}
.flexsupad .MuiCard-root {
  flex: 1 0 32.8%;
  height: 110px;
  font-size: 64px;
}
.d-flex.justify-content-between.flexsupad {
  row-gap: 5px;
}

.classGridSubs.MuiGrid-grid-lg-12 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  grid-column-gap: 10px;
  width: calc(1 / 3 * 100% - (1 - 1 / 3) * 10px);
}
.supAdminDash .thirdFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  row-gap: 5px;
}
.componentSec.supAdminDash {
  padding: 130px 20px 0px;
}

@media (max-width: 1024px) {
  .thirdFlex {
    flex: 0 0 99% !important;
    display: flex;
    justify-content: space-between;
  }
  .supAdminDash .thirdFlex {
    flex: 0 0 100% !important;
  }
  .supAdminDash .thirdFlex {
    justify-content: flex-start;
    align-content: inherit;
    column-gap: 5px;
  }
  .flexsupad .MuiCard-root {
    flex: 1 0 49%;
    min-height: 100px;
    justify-content: space-between;
    height: auto;
  }
  .supAdminDash .flexsupad .MuiCard-root {
    flex: 0 0 49%;
  }

  .supAdminDash .thirdFlex .flexsupad .MuiCard-root {
    flex: 0 0 49.6%;
    max-width: 49.6%;
  }
  .flexsupad,
  .thirdFlex {
    flex-wrap: wrap;
  }
  .flexsupad {
    width: 100%;
  }
  .supAdminDash .flexsupad .thirdFlex .MuiCard-root,
  .supAdminDash .flexsupad .thirdFlex {
    flex: 0 0 100%;
    flex-direction: row;
    min-height: 60px;
  }
  .supAdminDash .flexsupad > .MuiCard-root.mainCard:first-child {
    flex: 1 0 49.6%;
  }
}

@media (max-width: 767px) {
  .flexsupad .MuiCard-root,
  .supAdminDash .flexsupad .MuiCard-root {
    flex: 1 0 100%;
    max-width: 100%;
  }
  .flexsupad,
  .thirdFlex {
    flex-wrap: wrap;
  }
  .flexsupad {
    width: 100%;
  }
}
