
.recommendation h4{
  padding: 2px !important;
}
#assesment,
.deEscaCardDiv {
  width: 100%;
  row-gap: 5px;
  column-gap: 5px;
}
.row6 h4{
  padding: 2px;
}

#assesment .MuiPaper-root.MuiCard-root.mainCard.MuiPaper-elevation1.MuiPaper-rounded {
  height: 5rem;
}

.assmntTab th.MuiTableCell-root.MuiTableCell-body div {
  text-align: center !important;
}

.assmntTab th.MuiTableCell-root.MuiTableCell-body:first-child div {
  text-align: left !important;
}

.deEscaLineDiv p {
  height: 40px;
}

.recentAssSpanDiv p span {
  font-weight: 600;
  margin-right: 6px;
}

.msgP {
  color: #116a9a;
  text-align: center;
}

.recentAssSpanDiv>div {
  border-radius: 3px;
}

.deEscaLineDiv {
  row-gap: 5px;
  column-gap: 5px;
  justify-content: center;
}

.deEscaLineDiv .col-lg-4,
.deEscaCardDiv .col-lg-4 {
  max-width: 32.8%;
  padding: 0px;
}

.deEscaLineDiv div {
  border-radius: 4px;
}

.deEscaCardDiv .mainCard {
  margin-top: 0px;
  min-height: 110px;
}

@media (max-width: 1150px) {
  #assesment .noCardcus {
    font-size: 1rem !important;
  }
}

@media (min-width: 1150px) and (max-width: 1310px) {
  #assesment .noCardcus {
    font-size: 18px !important;
  }
}

@media (max-width: 991px) {
  #assesment .noCardcus {
    font-size: 1.5rem;
  }
}

@media (max-width: 1240px) {
  #assesment .deEscaLineDiv p {
    font-size: 0.7rem;
    line-height: 1.3;
  }
}

@media (max-width: 991px) {
  #assesment .deEscaLineDiv p {
    font-size: 1rem;
  }
}

#assesment .tableCover {
  margin-bottom: 20px !important;
  width: 100% !important;
  margin: 10px auto 0px auto;
}

#assesment .barChart {
  width: 97.4%;
  margin: auto;
  padding: 20px;
}

@media (max-width: 1380px) {

  #assesment .tableCover,
  #assesment .barChart {
    width: 100% !important;
  }
}

@media (max-width: 1150px) {

  #assesment .tableCover,
  #assesment .barChart {
    width: 96% !important;
  }
}

@media (max-width: 767px) {

  #assesment .tableCover,
  #assesment .barChart {
    width: 100% !important;
  }

  #assesment .deEscaLineDiv>div,
  #assesment .deEscaCardDiv>div {
    padding: 0px !important;
  }
}

#assesment .deEscaCardDiv .noCardcus {
  font-size: 23px;
}

.recentAssesmentcontainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
}

.recentAssesmentcontainer .recentAssesmentButton {
  display: flex;
  justify-content: center;
  width: 100%;
}

.recentAssesmentcontainer .recentAssesmentButton button {
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 20px;
  max-width: 150px;
}

.recentAssesmentcontainer .recentAssesmentRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  align-items: stretch;
}

.recentAssesmentRow .transcriptBlock1 {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 5px 20px;
  gap: 10px;
  border-radius: 3px;
  align-self: stretch;
  overflow-y: auto;
}

.scrollTranscript {
  max-height: 508px;
  overflow-y: auto;
}

.recentAssesmentRow h4 {
  padding: 0;
  text-align: center;
  margin: 0;
  color: #116a9a;
}

.recentAssesmentRow p {
  padding: 0;
  margin: 0;
}

.recentAssesmentRow .transcriptBlock2 {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-self: start; */
  overflow-y: auto;
  max-height: 508px;
}

.recentAssesmentRow .transcriptBlock2>div {
  background-color: #fff;
  padding: 5px 20px;
  flex: 1;
}

.recentAssesmentRow .transcriptBlock2 .row1>div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media (max-width:650px) {
  .recentAssesmentcontainer .recentAssesmentRow {
    flex-direction: column;
  }

  .recentAssesmentRow .transcriptBlock2 {
    flex: 1 1 100%;
    width: 100%;
  }
}