.lifeSupportContent {
  /* width: 75%; */
  /* width: auto !important;
  max-width: 73% !important;
  flex-basis: 73% !important; */
  flex:1 0 73% !important;
  max-width:73%;
  padding-left: 10px;
  
}
.sidenavsecond {
  /* flex-basis:25.8% !important; */
  flex:1 0 20% !important;
}
.combineFlexColmn{
  grid-column-gap: 5px;
}
.sidesearchlist .MuiAvatar-root.MuiAvatar-circular{
  margin: 0 auto;
}
.newIcon {
  /* width: 37px !important; */
  width: 100% !important;

  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
}
.newIcon .MuiSvgIcon-root {
  margin-right: 10px;
}
@media (min-width: 1250px) and (max-width:1500px){

}

@media (max-width: 1024px) {
  .sidenavsecond {
    flex-basis: 26% !important;
  }
  .sidesearchlist .MuiListItemAvatar-root {
    min-width: auto;
  }
}

/* @media (max-width: 1024px) {
	.sidenavsecond {
		max-width: 30%;
		flex-basis: 30%;
	}
	.lifeSupportContent {
		width: auto !important;
		max-width: 69% !important;
		flex-basis: 70% !important;
	}
} */
/* @media (max-width: 768px) {
	.sidenavsecond {
		max-width: 25% !important;
		flex-basis: 25% !important;
	}
	.lifeSupportContent {
		width: auto !important;
		max-width: 75% !important;
		flex-basis: 75% !important;
	}
} */

@media (min-width: 568px) and (max-width: 768px) {
  .fixNewIcon {
    white-space: break-spaces;
  }
}

@media (max-width: 568px) {
  .sidenavsecond {
    max-width: 100%;
    flex-basis: 100% !important;
  }
  .lifeSupportContent {
    width: auto !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
    border-left: none;
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
}
