.paperOrg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 10px !important;
    flex-wrap: wrap;
  }

  @media (max-width:1024px){
    .paperOrg{
        justify-content: flex-start;
    }
  }