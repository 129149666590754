button:focus {
  outline: none;
}
.signOutbtnP:hover {
  color: #116a9a !important ;
  background-color: #fff !important;
  border: 1px solid #116a9a !important;
}

.signOutbtnS:hover {
  color: #fff !important ;
  background-color: #116a9a !important;
  border: 1px solid #fff !important;
}
.iconButton .MuiSvgIcon-root {
  width: 20px !important;

  margin: 0px 5px 0px 0px;
}
