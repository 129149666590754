.crop-container {
  position: fixed;
  top: 10vh;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 9999;
  max-width: 70%;
  margin: 100px auto;
  height: auto;
}
.reactEasyCrop_Contain {
}
