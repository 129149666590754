.groupStart {
  margin-top: 94px;
}
.detailsIcon {
  flex: 0 0 40%;
  justify-content: space-around;
  align-items: center;
}
.detailsIcon svg {
  flex: 0 0 25%;
  color: #ffffff;
}
.detailsIcon .details {
  flex: 0 0 75%;
  color: #ffffff;
}
.banners {
  background: #ffffff;
  padding: 20px 20px 10px;
  border-radius: 3px;
}
.banners span {
  font-weight: 700;
  font-size: 20px;
  color: #167eb5;
}
.logoMobile {
  max-width: 100%;
  display: none;
}
.overViewBtn {
  margin-right: 10px;
}
.grpCardDiv {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  padding: 20px;
}

.leftGrp {
  display: flex;
  align-items: center;
}
.leftGrp .MuiSvgIcon-root {
  margin-right: 10px;
  color: white;
  background-color: #116a9a;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  padding: 5px;
}
.rightGrp {
  display: flex;
  align-items: center;
  width: auto !important;
}
.rightGrp .MuiButton-root {
  margin: 0 6px 0 10px;
}
.dltBtn {
  color: #f92e04;
  width: 30px !important;
}
.grpCardDiv h2 {
  font-size: 25px;
  font-weight: 900;
  word-break: break-word;
}
.grpCardDiv h4 {
  color: #130c2d;
  font-size: 0.875rem;
  margin: 0;
}
.AddUserDialog .MuiDialog-paper.MuiDialog-paperScrollPaper {
  text-align: center;
  max-width: 600px !important;
}
.AddUserDialog .MuiDataGrid-root .MuiDataGrid-columnHeader,
.AddUserDialog .MuiDataGrid-cell.MuiDataGrid-cell--textLeft,
.AddUserDialog
  .MuiDataGrid-root
  .MuiDataGrid-cell--textCenter.MuiDataGrid-cellCheckbox {
  max-width: initial !important;
  min-width: 40% !important;
  position: relative;
  color: #116a9a;
  font-weight: 600;
}
.AddUserDialog
  .MuiDataGrid-cell.MuiDataGrid-cellCheckbox.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell--textCenter,
.AddUserDialog
  .MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox {
  min-width: 80px !important;
  max-width: 15%;
}
.AddUserDialog .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
  text-align: center;
}
.AddUserDialog .MuiDataGrid-root .MuiDataGrid-menuIcon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.AddUserDialog .MuiDataGrid-iconButtonContainer {
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
}
.AddUserDialog .Mui-even.MuiDataGrid-row,
.AddUserDialog .Mui-odd.MuiDataGrid-row,
.AddUserDialog
  .MuiDataGrid-dataContainer
  .MuiDataGrid-viewport
  .MuiDataGrid-renderingZone {
  width: 100% !important;
}
.AddUserDialog .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  color: #000000;
  text-align: center;
  font-weight: 500;
}
.AddUserDialog .MuiTypography-colorTextSecondary {
  color: #000;
}
.AddUserDialog #form-dialog-title h2,
.AddUserDialog .MuiDialog-paper.MuiDialog-paperScrollPaper #form-dialog-title {
  font-weight: 600;
  padding-bottom: 0;
}
.AddUserDialog
  .MuiDataGrid-dataContainer
  .MuiDataGrid-viewport
  .MuiDataGrid-renderingZone {
  justify-content: space-between;
  align-items: center;
}
.AddUserDialog .MuiDataGrid-cell.MuiDataGrid-cell--textLeft:nth-child(2),
.MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader:nth-child(2) {
  display: none;
}
.AddUserDialog .MuiDataGrid-columnSeparator {
  display: none !important;
}

.fixingUI {
  padding: 0 !important;
}

.grp-chart-parent .pieOuterWrapper .pieName {
  font-size: 10px;
}

@media (max-width: 400px) {
  .btnDiff {
    flex-direction: column;
  }
}

.graphbgMainR.barChartSpecial .GraphBG {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.graphbgMainR.barChartSpecial .GraphBG {
  max-width: 49.6%;
}
.fixPadding.grp-chart-parent .flexsupad {
  column-gap: 5px;
  justify-content: center !important;
  row-gap: 5px;
  margin-top: 10px;
}
/* .fixPadding.grp-chart-parent .specialClass .flexsupad {
  justify-content: space-between !important;
} */
@media (max-width: 1200px) {
  .fixPadding.grp-chart-parent .flexsupad {
    column-gap: 5px;
    justify-content: center !important;
    row-gap: 5px;
    margin-top: 10px;
  }
}
@media (max-width: 991px) {
  .fixPadding.grp-chart-parent .flexsupad {
    column-gap: 5px;
  }
  .rightGrp {
    align-self: flex-end;
    margin-bottom: 5px;
    display: inline-flex;
    align-self: flex-start;
  }
  .leftGrp div:first-child {
    display: inline-flex;
    align-self: flex-start;
  }
  .GraphBG {
    margin-bottom: 10px;
  }
}
@media (max-width: 568px) {
  .leftGrp {
    align-items: baseline;
  }
}
