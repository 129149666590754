.backIcon {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: inline-block;
  background-color: white;
  color: white;
}
.backIcon .icon {
  margin: 3px auto;

  color: #116a9a;
}
.fullDiv {
  color: white;
  font-weight: bolder;
  font-size: 16px;
  display: flex;
  align-items: center;
}
