.userData .MuiInputBase-root{
color: #fff;
}
.userData label{
    color: white;
    
}
.userData fieldset{
 box-shadow: none;
border-color: white;    
}
/* .GenderDD{
    min-width: 120px;
} */
.textongrid{
    color: white;
    text-align: left;
    width: 100%;
    margin-bottom:15px;
}
.textBelow{
    color: white;
    margin-top:40px;
    width: 100%;
    text-align: left;
    margin-bottom:10px;
}
.belowLine {
display: flex;
justify-content: space-between;
width: 100%;
}
.accSettingMain .GenderDD .MuiSvgIcon-root.MuiSelect-icon {
    color:#ffffff !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.belowLine input{
    margin:0px 20px;
}
.accSettingMain .MuiFormControl-root.GenderDD,.accSettingMain .MuiFormControl-root.MuiTextField-root {
    width: 31%;
    margin: 0 1%;
}
.GenderDD  .MuiFormControl-root.GenderDD,.accSettingMain .MuiFormControl-root.MuiTextField-root {
    width: 31%;
    margin: 0 1%;
}
.accSettingMain .userData .MuiGrid-root.makeStyles-textBox-3.MuiGrid-container.MuiGrid-spacing-xs-3.MuiGrid-justify-xs-space-around.MuiGrid-grid-xs-12 {
    justify-content: flex-start;
}
.accSettingMain .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,.accSettingMain .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline,.accSettingMain .Mui-focused .MuiOutlinedInput-notchedOutline{
    border:1px solid #fff !important;
}
/* .accSettingMain .Mui-focused .MuiOutlinedInput-notchedOutline{
    border:none !important;
} */
.accSettingMain .MuiInputLabel-root.Mui-focused{
    color:#fff;
} 
.userData .MuiSelect-select {
    min-width: 100% !important;
}

  .GenderDD .MuiInputBase-formControl input{
    width: 220px;
    border: 0;
    position: relative;
    z-index: 99;
    background: none;
    pointer-events: none
  }
  select::-ms-expand,input::-ms-expand {
    display: none;
    }

@media only screen and (max-width:768px) {
    .accSettingMain .MuiFormControl-root.GenderDD, .accSettingMain .MuiFormControl-root.MuiTextField-root {
        width: 48%;
        margin: 0 1%;
    }
    .GenderDD .MuiInputBase-formControl input {
        width: 100%;
    }
    .detailsIconsFlex .detailsIconLower {
        flex: auto !important;
    }
    .belowLine{
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}
.settingUser{
    overflow-x: hidden;
}
@media only screen and (max-width:568px) {
    .accSettingMain .MuiFormControl-root.GenderDD, .accSettingMain .MuiFormControl-root.MuiTextField-root {
        width: 98%; 
        margin: 0 1%;
    }
}
    
