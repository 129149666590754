.d-flex1 canvas {
  display: inline-flex !important;
  max-width: 33% !important;
  width: 33% !important;
  height: auto !important;
  margin: 20px 0 10px 0;
}
.flexGraph {
  display: flex;

  flex-wrap: wrap;

  justify-content: space-between;
}

.flexGraph.codeBlack {
  align-items: center;
}

.graphbgMainL {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.fixgraphbgMainL {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.graphbgMainR {
  flex: 0 0 100%;
  max-width: 100%;
}
.graphbgMainR canvas,
.graphbgMainL canvas {
  width: 100% !important;
  height: auto !important;
}

.fixedPieCharts {
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
  gap: 0px;
  grid-gap: 0;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 10px;
}
.fixedPieCharts .pieOuterWrapper {
  margin: 0px 0px 0px 0px !important;
}

@media (max-width: 1435px) {
  .flexGraph {
    flex-wrap: wrap;
  }
  .graphbgMainL {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .graphbgMainR {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.grp-chart-parent .flexGraph {
  flex-wrap: wrap;
}
.grp-chart-parent .graphbgMainL,
.grp-chart-parent .graphbgMainR {
  flex: 0 0 100%;
  max-width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
}
.A_G .noCardcus {
  font-size: 13px;
}
.flexGraph.als {
  margin-top: 10px;
}

@media (max-width: 880px) {
  .d-flex1 .chartjs-render-monitor {
    max-width: 50% !important;
    width: 50% !important;
  }
  .fixedPieCharts .pieOuterWrapper {
    margin: 0px 0px 0px 0px !important;
  }
}

@media (max-width: 500px) {
  .d-flex1 .chartjs-render-monitor {
    max-width: 90% !important;
    width: 90% !important;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .grp-chart-parent .fixedPieCharts .col-lg-2 {
    flex: 0 0 32.333333%;
    max-width: 32.333333%;
  }
}
@media (max-width: 1230px) {
  .col-sm-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1350px) {
  .specialClass .col-sm-6 {
    /* flex: 0 0 100%;
    max-width: 100%; */
    /* padding-left: 5px !important;
    padding-right: 5px !important; */
  }
}

@media (max-width: 1200px) {
  .fixedPieCharts {
    gap:5px;
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .specialClass .col-sm-6 {
    padding: 0 !important;
  }
}
