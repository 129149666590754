.RSPBprogressBar {
  padding: 5px;
  box-shadow: 0px 0px 10px lightgrey;
  background-color: #ffffff;
  width: 50%;
  margin: 43px auto;
}
.RSPBprogressBar .RSPBstep {
  background-color: #116a9a;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  box-shadow: 0px 0px 10px lightgrey;
}
.pbTitles {
  margin-top: 30px;

  position: absolute;

  color: black;

  text-align: center;

  margin-left: -34px;

  font-weight: 550;
  white-space: nowrap;
}
.RSPBprogressBar .RSPBprogression {
  background: #116a9a !important;
}
.bar {
  width: 70%;
}
.pbTitles.pbTitles__center {
  margin-left: -55px;
}
