.d-flex1 canvas {
  display: inline-flex !important;
  max-width: 33% !important;
  width: 33% !important;
  height: auto !important;
  margin: 20px 0 10px 0;
}
.flexGraph {
  display: flex;

  flex-wrap: wrap;

  justify-content: space-between;
}

.flexGraph.codeBlack {
  align-items: center;
  margin-top: 10px;
}

.graphbgMainL {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.fixgraphbgMainL {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.graphbgMainR {
  flex: 0 0 100%;
  max-width: 100%;
}
.graphbgMainR canvas,
.graphbgMainL canvas {
  width: 100% !important;
  height: auto !important;
}
.gapPie {
  padding: 0px !important;
  flex: 0 0 19.3%;
  max-width: 19.3%;
}
/* .gapPie .pieOuterWrapper{
  margin-right:15px !important;

}


.gapPie:last-child .pieOuterWrapper{
  margin-right: 0 !important;
} */

.grp-chart-parent .fixedPieCharts {
  display: flex;
  grid-gap: 6px;
  width: 100%;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 10px;
}
.fixedPieCharts .pieOuterWrapper {
  margin: 0px 0px 0px 0px !important;
}

@media (max-width: 1435px) {
  .flexGraph {
    flex-wrap: wrap;
  }
  .graphbgMainL {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .graphbgMainR {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.grp-chart-parent .flexGraph {
  flex-wrap: wrap;
}
/* .grp-chart-parent .graphbgMainL {
  margin-top: 7px;
} */
.grp-chart-parent .graphbgMainL,
.grp-chart-parent .graphbgMainR {
  flex: 0 0 100%;
  max-width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
}
.A_G .noCardcus {
  font-size: 13px;
}

@media (max-width: 880px) {
  .d-flex1 .chartjs-render-monitor {
    max-width: 50% !important;
    width: 50% !important;
  }
}

@media (max-width: 500px) {
  .d-flex1 .chartjs-render-monitor {
    max-width: 90% !important;
    width: 90% !important;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .grp-chart-parent .fixedPieCharts .col-lg-2 {
    flex: 0 0 32.333333%;
    max-width: 32.333333%;
  }
}
@media (max-width: 1230px) {
  .col-sm-6 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1350px) {
  .specialClass .col-sm-6 {
    /* -ms-flex: 0 0 100%; */
    /* flex: 0 0 100%;
    max-width: 100%; */
    /* padding-left: 5px;
    padding-right: 5px; */
  }
}
/* .specialClass .col-sm-6 {
  padding-left: 5px !important;
  padding-right: 5px !important;
} */

@media (max-width: 1200px) {
  .gapPie {
    padding: 0;
    flex: 0 0 33%;
    max-width: 33%;
  }

  .grp-chart-parent .fixedPieCharts {
    gap: 5px;
    flex-wrap: wrap;
  }
}

.specialClass .flexsupad {
  row-gap: 5px;
  margin-top: 10px;
}
.specialClass .flexsupad .mainCard {
  margin-top: 0px !important;
}
@media (max-width: 768px) and (min-width: 576px) {
  .gapPie.col-sm-6 {
    flex: 0 0 49% !important;
    max-width: 49% !important;
  }
}
