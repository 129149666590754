.neeeeeewPie {
  /* height: 200px !important;
	width: 200px !important; */
  display: flex !important;
  /* flex-direction: column !important; */
}

.piieee {
  /* width: 450px; */
  width: 150px;
  max-width: 100%;
  /* padding:12px; */
}
.pieWrapper span {
  padding: 2px 10px;
  min-height: 44px;
  display: flex;
  align-items: center;
  font-weight: 500;
  background: #f2f2f2;
  width: 100%;
  max-width: 100%;
  box-shadow: 2px 0px 1px #e9edef;
}

.pieWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
}

.pieOuterWrapper {
  margin: 30px 0;
}

.pieOuterWrapper .pieName {
  background-color: #fff;
  font-weight: 500;
  border-radius: 3px;
  min-height: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .piieee {
    width: 330px;
  }
}

@media screen and (max-width: 468px) {
  .piieee {
    width: 345px;
    flex-wrap: wrap;
    font-size: 10px;
    justify-content: center;
    margin: 0 auto;
  }
  .pieWrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 320px) {
  .piieee {
    width: 280px;
    justify-content: center;
  }
}
