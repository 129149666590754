.detailModalContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  max-width: 100%;
  width: 100%;
  max-width: 500px;
  padding: 0;
  overflow: auto;
  font-family: sans-serif;
}

.detailModalContainer .heading {
  flex: 1 1 30%;
}

.detailModalContainer .heading ul {
  padding: 0;
  margin: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 35px;
}
.detailModalContainer .heading ul li {
  display: flex;
  gap: 25px;
  list-style: none;
  font-weight: 600;
  font-size: 16px;
}

.detailModalContainer .dataContainer {
  flex: 1 1 70%;
}

.detailModalContainer .dataContainer ul {
  padding: 0;
  margin: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 39px;
}
.detailModalContainer .dataContainer ul li {
  display: flex;
  gap: 25px;
  list-style: none;
  font-weight: normal;
  /* font-size: 17px; */
}
.cancelButton {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  padding: 10px 0;
}
.cancelButton button {
  background-color: #116a9a;
  color: white;
  border: none;
}
