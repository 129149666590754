.details .MuiDialog-paperWidthSm {
  max-width: 600px;
  border-radius: 3px;
  width: 50%;
  display: flex;
  flex-direction: column;
  z-index: 11;
}
.inviteModalStart input {
  width: 198px;
  height: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #130c2d;
}
.AddUserDialog .MuiDialog-paperWidthSm {
  max-width: 1000px;
  border-radius: 10px;
  width: 70%;
  height: 80% !important;
  overflow: auto;
  /* height: auto !important; */
  /* min-height: 60% !important; */
}
.AddUserDialog .MuiDataGrid-root {
  height: 90% !important;
}
.super-admin-invite-org-container {
  position: static !important;
}
