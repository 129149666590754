.parentBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 460px) {
  .fixingParentBox {
    display: flex;
    flex-direction: column;
  }
}
