

@media (max-width:991px){
    .detailsIconsFlex{
        flex-wrap:wrap;
    }
    .detailsIcon .details {
        flex: auto;
    }
    .detailsIcon .details h6{
        white-space: pre;
    }
    .detailsIcon{
        flex-wrap:wrap;
    }
}

@media (max-width:568px){
    .d-flex.justify-content-around.banners {
        flex-wrap: wrap;
    }
    .detailsIconLower {
        flex: auto;
        max-width: 100%;
    }
    .banner_2 {
        max-width: 100%;
        flex-wrap: wrap;
    }
    .banner_2 .MuiButtonBase-root.signOutbtn {
        margin-bottom: 8px;
    }
}