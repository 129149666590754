.details.listinmodel {
  flex: 0 0 33%;
}
.details .listinmodel li {
  margin-top: 10px;
  list-style: none;
}
.detailsINfo {
  z-index: 99999 !important;
}
.detailsINfo .MuiDialog-paperWidthSm {
  max-width: 1000px !important;
  width: 100% !important;
  /* max-width: 600px; */
  height: 100% !important;
}
.details .listinmodel li .MuiOutlinedInput-input {
  padding: 19.5px 65px;
}
.detailsINfo .MuiDialog-paperWidthSm {
  max-width: 1000px;
  border-radius: 25px;
  width: 100% !important;
  padding: 20px;
}
.detailsINfo .MuiOutlinedInput-input {
  padding: 18px 30px !important;
}
.details .ModelIcon {
  color: #116a9a;
  width: 120px !important;
}
.model-head {
  position: relative;
}
.details .model-head .modelIcon2 {
  position: absolute;
  top: 20px;
  right: 20px;
}
