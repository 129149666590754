body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "PT Sans", sans-serif !important;
}

.privacyContent h3 {
  font-size: 22px;
}

.showNavParentRoot ul li,
.showNavParentRoot ul > div {
  margin-bottom: 10px !important;
}
