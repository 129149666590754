.cb {
  flex-wrap: wrap;
  margin-top: 10px;
  column-gap: 5px;
  row-gap: 5px;
}
.cb .MuiCard-root {
  flex: 0 0 32.6%;

  height: 110px;
  font-size: 64px;
}
.cusCard {
  flex:1 0 24%;
}
.bar-chart {
  flex: 0 0 49.6%;
}

@media (max-width: 1565px) {
  .grp-chart-parent .cb .cusCard {
    flex: 0 0 49.6%;
    max-width: 49.6%;
  }
}

@media (max-width: 1024px) {
  .cb .MuiCard-root {
    flex: 0 0 49.6%;
    /* max-width: 49%; */
    justify-content: space-between;
    /* height: auto; */
  }

  .cb {
    width: 100%;
    column-gap: 5x;
  }
}

@media (max-width: 1192px) and (min-width: 991px) {
  .cb {
    column-gap: 5px;
  }
}
@media (max-width: 992px) {
  .cusCard {
    flex:1 0 49%;
  }
  .bar-chart {
    flex: 1 0 100%;
  }
}
@media (max-width: 815px) {
  .grp-chart-parent .cb .cusCard {
    flex: 1 0 49.3%;
    max-width: 49.3%;
  }
  .graphbgMainR.barChartSpecial .GraphBG {
    flex:1 0 48.6%;
    max-width: 48.6%;
  }
}
@media (max-width: 680px) {
  .grp-chart-parent .cb .cusCard {
    flex: 1 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .cb .MuiCard-root {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cb {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .cusCard {
    flex: 1 0 100%;
  }
}

.codeBlack .graphbgMainL {
  margin-top: 0px !important;
}
